<template>
    <div>
        <div class="date-row">
            <ValidationProvider mode="lazy" rules="required" name="Дата" tag="div" class="date-row__day">
                <div slot-scope="{ valid, errors }">
                    <date-picker
                        :input-class="errors[0] ? 'form-control is-invalid' : (valid ? 'form-control is-valid' : 'form-control')"
                        type="date"
                        v-model="date.day"
                        format="DD.MM.YYYY"
                        placeholder="__.__.____"
                        :disabled-date="disableDateBeforeStartAndAfterEndForum"
                        :default-value="new Date(value.dateStartForum)"
                        value-type="timestamp"
                    ></date-picker>
                    <b-form-input
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-show="false"
                        type="date"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </div>
            </ValidationProvider>
            <ValidationProvider mode="lazy" rules="required" name="Начало лекции" tag="div" class="date-row__time time-from">
                <div slot-scope="{ valid, errors }">
                    <date-picker
                        :input-class="errors[0] ? 'form-control is-invalid' : (valid ? 'form-control is-valid' : 'form-control')"
                        type="time"
                        v-model="date.fromTime"
                        format="HH:mm"
                        placeholder="__:__"
                        :disabled="!date.day"
                        :default-value="defaultTimeStart"
                        :disabled-time="disableBeforeStartForum"
                        @clear="date.toTime = null"
                    ></date-picker>
                    <b-form-input
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-show="false"
                        type="date"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </div>
            </ValidationProvider>
            <div>-</div>
            <ValidationProvider mode="lazy" rules="required" name="Конец лекции" tag="div" class="date-row__time">
                <div slot-scope="{ valid, errors }">
                    <date-picker
                        :input-class="errors[0] ? 'form-control is-invalid' : (valid ? 'form-control is-valid' : 'form-control')"
                        type="time"
                        v-model="date.toTime"
                        format="HH:mm"
                        placeholder="__:__"
                        :disabled="!date.fromTime"
                        :default-value="date.fromTime"
                        :disabled-time="disabledToTime"
                    ></date-picker>
                    <b-form-input
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-show="false"
                        type="date"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>
                        {{ errors[0] }}
                    </b-form-invalid-feedback>
                </div>
            </ValidationProvider>
        </div>
    </div>

</template>

<script>
    export default {
        name: "DateTimeFromTo",
        props: {
            value: {
                type: Object
            }
        },
        watch: {
            value(range){
                if(!range.timeStart && !range.timeEnd){
                    this.date.day = null;
                    this.date.fromTime = null;
                    this.date.toTime = null;
                }
                if(range.timeStart && range.timeEnd){
                    this.date.day = range.timeStart;

                    this.$nextTick(() => {
                        this.date.fromTime = new Date(range.timeStart);
                        this.date.toTime = new Date(range.timeEnd);
                    })
                }
            },
            'date.day'(value){
                // Задаем время по умолчанию для поля выбора времени начала Ивента
                const dayStart = new Date(this.value.dateStartForum).setHours(0,0,0,0);
                // const daySelected = this.date.day;
                const daySelected = new Date(this.date.day).setHours(0,0,0,0);
                if(dayStart === daySelected){
                    this.defaultTimeStart = new Date(this.value.dateStartForum).setHours(new Date(this.value.dateStartForum).getHours());
                } else {
                    this.defaultTimeStart = new Date(value).setHours(10, 0, 0, 0);
                }

                // Обнулить время начала и конца
                this.date.fromTime = null;
                this.date.toTime = null;

            },
            'date.fromTime'(newValue, oldValue){
                if(newValue && newValue !== oldValue) {
                    let point = null;
                    // Добавляем к дате дня время (часы и минуты)
                    point = new Date(this.date.day);
                    point.setHours(newValue.getHours());
                    point.setMinutes(newValue.getMinutes());
                    this.range.timeStart = point.getTime();
                    this.$emit('input', this.range);
                }
            },
            'date.toTime'(newValue, oldValue){
                if(newValue && newValue !== oldValue) {
                    let point = null;
                    // Добавляем к дате дня время (часы и минуты)
                    point = new Date(this.date.day);
                    point.setHours(newValue.getHours());
                    point.setMinutes(newValue.getMinutes());
                    this.range.timeEnd = point.getTime();
                    this.$emit('input', this.range);
                }
            }
        },
        methods: {
            // Запрет выбора дней до начала форума и после окончания форума
            disableDateBeforeStartAndAfterEndForum(date){
                const start = this.value.dateStartForum - 3600 * 1000 * 24;
                if(this.value.dateEndForum){
                    const end = this.value.dateEndForum - 3600 * 1000;
                    return date < start || date > end;
                }
                return date < start;
            },
            // Запрет выбора времени Ивента до выбранного времени начала Ивента
            disabledToTime(date){
                if(this.date.fromTime){
                    return date < this.date.fromTime
                }
            },
            // Запрет выбора времени до начала форума
            disableBeforeStartForum(time){
                const dayStart = new Date(this.value.dateStartForum).setHours(0,0,0,0);
                const daySelected = this.date.day;
                if(dayStart <= daySelected){
                    const hours = new Date(this.value.dateStartForum).getHours();
                    const minutes = new Date(this.value.dateStartForum).getMinutes();
                    return time < new Date(this.value.dateStartForum).setHours(hours, minutes, 0, 0);
                }
            },
        },
        data(){
            return{
                range: {
                    timeStart: null,
                    timeEnd: null,
                },
                date: {
                    day: null,
                    fromTime: null,
                    toTime: null
                },
                defaultTimeStart: null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .date-row{
        display: flex;
        flex-wrap: nowrap;
        align-items: baseline;
        .mx-datepicker{
            width: 100%;
        }
        &__day{
            width: 100%;
            max-width: 130px;
        }
        &__time{
            width: 100%;
            max-width: 96px;
            margin: 0 10px;
        }
    }
    /*@media screen and (min-width: 1025px) and (max-width: 1440px) {*/
        /*.date-row{*/
            /*flex-direction: column;*/
        /*}*/
        /*.date-row__day{*/
            /*width: 100%;*/
        /*}*/
    /*}*/
</style>