<template>
    <div>
        <label v-if="selectedSpeakers.length">Спикеры лекции</label>
        <event-speaker-list v-if="selectedSpeakers.length" :selectedSpeakers="selectedSpeakers" @deleteSpeaker="deleteSpeaker"/>
        <event-speaker-form :availableSpeakers="availableSpeakers" :selectedSpeakers="selectedSpeakers" @addSpeaker="addSpeaker"/>
    </div>
</template>

<script>
    import EventSpeakerList from '@/components/forms/event/EventSpeakerList'
    import EventSpeakerForm from '@/components/forms/event/EventSpeakerForm'

    export default {
        name: "EventSpeaker",
        props: {
            availableSpeakers: {
                type: Array,
                required: true
            },
            selectedSpeakers: {
                type: Array,
                required: true
            }
        },
        components: {
            EventSpeakerList,
            EventSpeakerForm
        },
        methods: {
            addSpeaker(speaker){
                this.$emit('addSpeaker', speaker);
            },
            deleteSpeaker(id){
                this.$emit('deleteSpeaker', id);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>