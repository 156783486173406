<template>
    <div class="dashboard">
        <router-link :to="{ name: 'forum-details', params: { forumId: forumId } }" class="back-btn mb-3">
            <b-icon icon="chevron-left"></b-icon>
            <span>назад к деталям ивента</span>
        </router-link>
        <div v-if="forum">
            <main-forum-info class="mb-4" :isEdit="isEdit" :forumData="forum" @updateDataForum="updateDataForum"/>
            <event-program id="eventAnchor" :isEdit="isEdit" :forumData="forum" @updateDataForum="updateDataForum"/>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import MainForumInfo from '@/components/forms/forum/MainForumInfo'
    import EventProgram from '@/components/event/EventProgram'

    export default {
        name: "edit",
        components: {
            MainForumInfo,
            EventProgram
        },
        computed: {
            ...mapGetters([
                'forumByID',
                'eventsByForumId'
            ]),
            forumId(){
                return this.$route.params.forumId
            },
        },
        methods: {
            ...mapActions([
                'getForumById',
            ]),
            updateDataForum(id){
                // if(this.isEdit){
                //     this.forum = Object.assign({}, this.forumByID(id));
                // } else {
                //     this.forum = this.forumByID(id);
                // }
                this.forum = this.forumByID(id);
                const speakers = this.forum.speakers;
                const materials = this.forum.materials;
                const events = this.forum.events;
                this.$set(this.forum, speakers, materials, events);
            },
        },
        mounted(){
            this.getForumById(this.forumId).then(forum => {
                this.forum = forum
            });
        },
        watch: {
            forum(value){
                if(value){
                    this.$nextTick(() => {
                        // Скролл к форме Программа ивента
                        if(this.$route.hash){
                            const el = document.getElementById('eventAnchor');
                            const offsetY = el.offsetTop;
                            window.scrollTo({
                                top: offsetY,
                                behavior: "smooth"
                            });
                        }
                    })
                }
            }
        },
        data(){
            return {
                isEdit: true,
                forum: null,
            }
        }
    }
</script>