<template>
    <div>
        <ValidationObserver ref="programEvent">
            <b-form class="mb-3" slot-scope="{ validate }" @submit.prevent="handleSubmit">
                <div v-if="isEdit">
                    <div class="row">
                        <ValidationProvider rules="required" name="Сцена" tag="div" class="col-md-6">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Название сцены</label>
                                <v-select placeholder="Выберите сцену" :class="errors.length ? 'is-invalid' : ''" :options="forumData ? forumData.rooms : []" v-model="program.room">
                                    <template #no-options="{ search, searching, loading }">
                                        Извините, нет доступных сцен
                                    </template>

                                    <template #selected-option="selected">
                                        {{selected.label.split(',').join(', ')}}
                                    </template>

                                    <template #option="option">
                                        {{option.label.split(',').join(', ')}}
                                    </template>
                                </v-select>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>

                        <ValidationProvider rules="required|min:3|max:50" name="Название лекции" tag="div" class="col-md-6">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Название лекции</label>
                                <b-form-input
                                    v-model="program.title"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    placeholder="Введите название лекции">
                                </b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>

                    <div class="row">
                        <ValidationProvider rules="required|min:3|max:250" name="Описание лекции" tag="div" class="col-md-6">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Описание лекции</label>
                                <b-form-input
                                    v-model="program.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    placeholder="Краткое описание лекции">
                                </b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <div class="col-md-6 form-group">
                            <label>Дата и время лекции</label>
                            <time-range v-model="programTime"/>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="row">
                        <ValidationProvider rules="required" name="Сцена" tag="div" class="col-xl-4">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Название сцены</label>
                                <v-select placeholder="Выберите сцену" :class="errors.length ? 'is-invalid' : ''" :options="forumData ? forumData.rooms : []" v-model="program.room">
                                    <template #no-options="{ search, searching, loading }">
                                        Извините, нет доступных сцен
                                    </template>

                                    <template #selected-option="selected">
                                        {{selected.label.split(',').join(', ')}}
                                    </template>

                                    <template #option="option">
                                        {{option.label.split(',').join(', ')}}
                                    </template>
                                </v-select>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider rules="required|min:3|max:50" name="Название лекции" tag="div" class="col-xl-4 title-box">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Название лекции</label>
                                <b-form-input
                                    v-model="program.title"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    placeholder="Введите название лекции">
                                </b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required|min:3|max:250" name="Описание лекции" tag="div" class="col-xl-4 title-box">
                            <div class="form-group" slot-scope="{ valid, errors }">
                                <label>Описание лекции</label>
                                <b-form-input
                                    v-model="program.description"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    placeholder="Краткое описание лекции">
                                </b-form-input>
                                <b-form-invalid-feedback>
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </div>
                        </ValidationProvider>
                        <div class="col-xl-4 form-group time-box">
                            <label>Дата и время лекции</label>
                            <time-range v-model="programTime"/>
                        </div>
                    </div>
                </div>

                <event-speaker
                    class="lecture-speaker-box"
                    :availableSpeakers="forumData ? forumData.speakers : []"
                    :selectedSpeakers="selectedSpeakers"
                    @addSpeaker="addSpeaker"
                    @deleteSpeaker="deleteSpeaker"
                />

                <div v-if="isEdit" class="text-center">
                    <b-button type="submit" variant="primary submit btn-50">
                        <span class="btn-txt">сохранить</span>
                    </b-button>
                </div>

                <div v-else>
                    <b-button type="submit" variant="primary submit" :disabled="!forumData._id || isBusy">
                        <b-spinner v-if="isBusy" small type="grow"></b-spinner>
                        <span class="btn-txt" v-if="isBusy">Сохранение...</span>
                        <span class="btn-txt" v-else>добавить</span>
                    </b-button>
                </div>

            </b-form>
        </ValidationObserver>
    </div>
</template>

<script>
    import TimeRange from '@/components/forms/TimeRange'
    import EventSpeaker from '@/components/forms/event/EventSpeaker'
    import { errorsAlert } from '@/helpers/errorsAlert'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    import { mapActions } from 'vuex'

    export default {
        name: "EventProgramForm",
        components: {
            TimeRange,
            EventSpeaker
        },
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            forumData: {
                type: Object
            },
            eventData: {
                type: Object
            },
            isShowModal: {
                type: Boolean
            }
        },
        computed:{
            programTime: {
                get(){
                    const rangeTime = {
                        timeStart: this.program.timeStart,
                        timeEnd: this.program.timeEnd,
                        dateStartForum: this.forumData.dateStart,
                        dateEndForum: this.forumData.dateEnd,
                    };
                    return rangeTime;
                },
                set(value){
                    this.program.timeStart = value.timeStart;
                    this.program.timeEnd = value.timeEnd;
                }
            }
        },
        methods: {
            ...mapActions([
                'saveNewEvent',
                'saveEventSpeaker',
                'editEvent',
                'deleteSpeakerByEventId'
            ]),
            addSpeaker(newSpeaker){
                // Проверка, есть ли уже данный спикер в ивенте
                let isSpeaker = false;
                for (let i = 0, len = this.selectedSpeakers.length; i < len; i++) {
                    const item = this.selectedSpeakers[i];
                    if(item.speaker === newSpeaker.speaker) {
                        isSpeaker = true;
                        errorsAlert('Данный спикер уже назначен на текущую лекцию');
                        break;
                    }
                    isSpeaker = false;
                }

                if(!isSpeaker){
                    this.selectedSpeakers.push(newSpeaker);

                    if(this.isEdit){
                        this.addedSpeakers.push(newSpeaker);
                    }
                }
            },
            deleteSpeaker(id){
                this.selectedSpeakers.map((item, index) => {
                    if(item.speaker === id){
                        this.selectedSpeakers.splice(index, 1);
                    }
                });

                // Удаленные спикеры при редактировании ивента
                if(this.isEdit){
                    this.deletedSpeakers.push(id)
                }
            },
            async handleSubmit(){
                const isValid = await this.$refs.programEvent.validate();
                if(isValid){
                    const dataEvent = {
                        // forumId: взять с респонса запроса POST /forum (Create Forum)
                        forumId: this.forumData._id,
                        title: this.program.title,
                        description: this.program.description,
                        timeStart: this.program.timeStart,
                        timeEnd: this.program.timeEnd,
                        room: this.program.room
                    };

                    if(this.isEdit){
                        const editData = {
                            eventId: this.eventData._id,
                            event: dataEvent
                        };

                        // Удаление спикеров (если были удалены при редактировании ивента)
                        if(this.deletedSpeakers.length){
                            let speakerRequests = [];
                            for(let idx in this.deletedSpeakers){
                                const data = {
                                    forumId: this.forumData._id,
                                    eventId: this.eventData._id,
                                    speakerId: this.deletedSpeakers[idx],
                                };
                                speakerRequests.push(this.deleteSpeakerByEventId(data)
                                    .catch(error => {
                                        errorsAlertApi(error);
                                    }
                                ));
                            }
                            Promise.all(speakerRequests).then(() => {
                                this.deletedSpeakers = [];
                            });
                        }

                        // Добавление спикеров (если были добавлены при редактировании ивента)
                        if(this.addedSpeakers.length){
                            const speakersData = {
                                eventId: this.eventData._id,
                                speakers: []
                            };

                            for (let item of this.addedSpeakers) {
                                speakersData.speakers.push({
                                    speaker: item.speaker,
                                    role: item.role
                                })
                            }

                            this.saveEventSpeaker(speakersData).then(() => {
                                this.addedSpeakers = [];
                            })
                                .catch(error => {
                                    errorsAlertApi(error);
                                    this.isBusy = false;
                                })
                        }

                        this.editEvent(editData).then(() => {
                            this.$emit('updateDataForum', this.forumData._id);
                            this.$bvModal.hide('edit-event');
                        });


                    } else {
                        this.saveNewEvent(dataEvent).then(event => {
                            if(this.selectedSpeakers.length){
                                const speakersData = {
                                    eventId: event._id,
                                    speakers: []
                                };

                                for (let item of this.selectedSpeakers) {
                                    speakersData.speakers.push({
                                        speaker: item.speaker,
                                        role: item.role
                                    })
                                }

                                this.saveEventSpeaker(speakersData)
                                    .catch(error => {
                                    errorsAlertApi(error);
                                    this.isBusy = false;
                                })
                            }
                            this.selectedSpeakers = [];
                        }).catch(error => {
                            errorsAlertApi(error);
                            this.isBusy = false;
                        });

                        this.$emit('updateDataForum', this.forumData._id);

                        // Очистить поля формы
                        this.program.room = null;

                        this.program.timeStart = null;
                        this.program.timeEnd = null;
                        this.programTime = {
                            timeStart: null,
                            timeEnd: null
                        };


                        this.program.title = null;
                        this.program.description = null;

                        this.$nextTick(() => {
                            this.$refs.programEvent.reset();
                        })
                    }
                }
            },
        },
        watch: {
            isShowModal(value){
                if(value){
                    this.program.room = this.eventData.room;
                    this.program.title = this.eventData.title;
                    this.program.description = this.eventData.description;
                    const speakers = this.eventData.speakers.map(item => {
                        const role = item.role;
                        const speaker = item.speaker._id;
                        const photo = item.speaker.photo;
                        const name = item.speaker.name;
                        return {
                            role,
                            speaker,
                            photo,
                            name
                        }
                    });
                    this.selectedSpeakers = speakers;

                    const timeStart = this.eventData.timeStart;
                    const timeEnd = this.eventData.timeEnd;
                    this.programTime = { timeStart, timeEnd }
                } else {
                    this.selectedSpeakers = [];
                    this.program.room = null;
                    this.program.title = null;
                    this.program.description = null;
                }
            }
        },
        data(){
            return {
                isBusy: false,
                program: {
                    room: null,
                    timeStart: null,
                    timeEnd: null,
                    title: null,
                    description: null
                },
                selectedSpeakers: [],
                deletedSpeakers: [],
                addedSpeakers: [],
            }
        }
    }
</script>

<style lang="scss" scoped>
    label{
        display: block;
    }
    .submit{
        padding-left: 30px;
        padding-right: 30px;
    }
    .lecture-speaker-box{
        max-width: 1045px;
    }
    .btn-50{
        width: 50%;
        min-width: 180px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1440px) {
        .time-box{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .title-box{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
</style>