var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col-md-6 form-group"},[_c('label',{class:_vm.selectedSpeakers.length ? 'invisible' : ''},[_vm._v("Спикеры лекции")]),_c('v-select',{staticClass:"dropdown-up",class:!_vm.isValidSpeaker ? 'is-invalid' : '',attrs:{"placeholder":"Выберите спикера","options":_vm.availableSpeakers,"label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных спикеров ")]}},{key:"option",fn:function(ref){
var name = ref.name;
var photo = ref.photo;
return [_c('div',{staticClass:"speaker-item"},[_c('img',{staticClass:"speaker-item__photo",attrs:{"src":photo ? photo : _vm.defaultPhoto,"alt":""}}),_c('span',{staticClass:"speaker-item__name"},[_vm._v(_vm._s(name))])])]}}]),model:{value:(_vm.speakerSelect),callback:function ($$v) {_vm.speakerSelect=$$v},expression:"speakerSelect"}}),(!_vm.isValidSpeaker)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Выберите спикера")]):_vm._e()],1),_c('div',{staticClass:"col-md-6 form-group"},[_c('label',[_vm._v("Роль спикера")]),_c('b-form-radio-group',{staticClass:"role"},[_c('b-form-radio',{attrs:{"name":"role","value":"KEY_SPEAKER"},model:{value:(_vm.speaker.role),callback:function ($$v) {_vm.$set(_vm.speaker, "role", $$v)},expression:"speaker.role"}},[_vm._v("Ключевой спикер")]),_c('b-form-radio',{attrs:{"name":"role","value":"SPEAKER"},model:{value:(_vm.speaker.role),callback:function ($$v) {_vm.$set(_vm.speaker, "role", $$v)},expression:"speaker.role"}},[_vm._v("Спикер")]),_c('b-form-radio',{attrs:{"name":"role","value":"MODERATOR"},model:{value:(_vm.speaker.role),callback:function ($$v) {_vm.$set(_vm.speaker, "role", $$v)},expression:"speaker.role"}},[_vm._v("Модератор")]),(!_vm.isValidRole)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Выберите роль спикера")]):_vm._e()],1)],1)]),_c('div',{staticClass:"mb-4"},[_c('button',{staticClass:"add-btn",attrs:{"type":"button"},on:{"click":_vm.addSpeaker}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill"}}),_c('span',[_vm._v("Добавить спикера")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }