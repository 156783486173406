<template>
    <div v-if="events">
        <div class="f-table auto-scroll-vertical">
            <div class="f-cell" v-for="(event, id) in events" :key="event._id">
                <div class="f-row">
                    <div class="f-td f-td-scene">{{event.room.split(',').join(', ')}}</div>
                    <div class="f-td f-td-lecture">{{event.title}}</div>
                    <div class="f-td f-td-desc">{{event.description}}</div>
                    <div class="f-td f-td-date">{{parseDate(event.timeStart)}}</div>
                    <div class="f-td f-td-date">{{parseTime(event.timeStart, event.timeEnd)}}</div>
                    <div class="f-td w-30p">
                        <button class="btn-crl" v-b-toggle="event._id">
                            <b-icon icon="chevron-down" font-scale="1.5"></b-icon>
                        </button>
                    </div>
                    <div class="f-td w-30p">
                        <span class="btn-crl" @click="handleEditEvent(event, id)">
                            <b-icon icon="pencil-fill"></b-icon>
                        </span>
                    </div>
                    <div class="f-td w-30p">
                        <span class="btn-crl" @click="handleDeleteEvent(event._id)">
                            <b-icon icon="x-circle-fill"></b-icon>
                        </span>
                    </div>
                </div>
                <b-collapse :id="event._id" class="f-description">
                    <div class="speaker-group" v-if="roleGroup.speakers.length" v-for="roleGroup in groupSpeakersByRole(event.speakers)">
                        <div class="speaker-list__title">
                            {{roles[roleGroup.roleName].title}}
                        </div>
                        <div class="speaker-list">
                            <span class="speaker-item" v-for="(item, index) in roleGroup.speakers">
                                {{item.speaker.name}}{{roleGroup.speakers.length > index + 1 ? ', ' : ''}}
                            </span>
                        </div>
                    </div>
                    <b-alert v-if="!event.speakers.length" show variant="secondary">На данный ивент не назначены спикеры</b-alert>
                </b-collapse>
            </div>
        </div>
        <edit-event
            :eventData="eventData"
            :forumData="forumData"
            @updateDataForum="updateDataForum"
        />
    </div>
</template>

<script>
    import parseTime from '@/mixins/parseTime'
    import parseDate from '@/mixins/parseDate'
    import EditEvent from '@/components/modals/EditEvent'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    import { mapActions } from 'vuex'

    export default {
        name: "EventList",
        components: {
            EditEvent
        },
        mixins: [
            parseTime,
            parseDate
        ],
        props: {
            forumData: {
                type: Object
            }
        },
        methods:{
            ...mapActions([
                'deleteEvent'
            ]),
            handleEditEvent(event, id){
                this.$emit('handleEditEvent', {event, id});
                this.eventData = event;
                this.$bvModal.show('edit-event');
            },
            handleDeleteEvent(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить программу?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        const dataDelete = {
                            eventId: id,
                            forumId: this.forumData._id
                        };
                        this.deleteEvent(dataDelete).then(() => {
                            this.$emit('updateDataForum', this.forumData._id);
                            this.$nextTick(() => {
                                this.sortEventsByDate();
                            })
                        })
                            .catch(error => {
                                errorsAlertApi(error);
                            });
                    }
                })
            },
            groupSpeakersByRole(speakers){
                // Групируем спикеров по ролям
                return Object.keys(this.roles).map(roleName => {
                    return {
                        roleName,
                        speakers: speakers.filter(speaker => {
                            return speaker.role === roleName
                        })
                    }
                })
            },
            updateDataForum(id){
                this.$emit('updateDataForum', id);
            },
            sortEventsByDate(){
                this.events = this.forumData.events ? this.forumData.events.sort((a, b) => a.timeStart - b.timeStart) : [];
            }
        },
        mounted(){
            this.sortEventsByDate();
        },
        beforeUpdate(){
            this.sortEventsByDate();
        },
        data(){
            return {
                events: [],
                eventData: {},
                roles: {
                    'KEY_SPEAKER': {
                        title: 'Ключевой спикер:'
                    },
                    'SPEAKER': {
                        title: 'Спикеры лекции:'
                    },
                    'MODERATOR': {
                        title: 'Модераторы лекции:'
                    }
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .f-table{
        background: #f0f0f0;
        border-radius: 6px;
        padding: 0 12px;
        max-height: 295px;
        .f-row{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
        .f-td{
            padding: 12px 10px;
            &.f-td-scene{
                width: 20%;
            }
            &.f-td-lecture{
                width: 20%;
            }
            &.f-td-desc{
                width: 25%;
            }
            &.f-td-date{
                width: 130px;
            }
        }
        .w-30p{
            width: 30px;
            padding: 0;
            text-align: center;
        }
        .f-cell{
            border-bottom: 1px solid #ffffff;
            &.active{
                background: #fff;
                border-radius: 6px;
                box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
                .f-description{
                    box-shadow: none;
                }
            }
        }
    }
    .f-description{
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
        padding: 5px 10px;
        .speaker-group {
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .speaker-list{
        &__title{
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 3px;
        }
    }
    .btn-crl{
        &.not-collapsed{
            transform: rotate(180deg);
        }
    }
</style>