<template>
    <b-modal id="edit-event" size="xl" centered hide-footer v-model="editEvent">
        <div class="modal-wrapper">
            <div class="h1">Информация о лекции</div>
            <EventProgramForm
                :isEdit="true"
                :eventData="eventData"
                :forumData="forumData"
                :isShowModal="editEvent"
                @updateDataForum="updateDataForum"
            />
        </div>
    </b-modal>
</template>

<script>
    import EventProgramForm from '@/components/forms/event/EventProgramForm'

    export default {
        name: "EditEvent",
        components: {
            EventProgramForm
        },
        props: {
            eventData: {
                type: Object,
                required: true
            },
            forumData: {
                type: Object
            }
        },
        methods: {
            updateDataForum(id){
                this.$emit('updateDataForum', id);
            },
        },
        data(){
            return {
                editEvent: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h1{
        text-align: center;
    }
</style>