<template>
    <div class="wrapper">
        <div class="h1">Программа ивента</div>
        <EventProgramForm
            :isEdit="!isEdit"
            :forumData="forumData"
            @updateDataForum="updateDataForum"
        />
        <event-list
            :forumData="forumData"
            @updateDataForum="updateDataForum"
        />
    </div>
</template>

<script>
    import EventProgramForm from '@/components/forms/event/EventProgramForm'
    import EventList from '@/components/forms/event/EventList'

    export default {
        name: "EventProgram",
        components: {
            EventProgramForm,
            EventList
        },
        props: {
            isEdit: {
                type: Boolean,
                required: true
            },
            forumData: {
                type: Object
            },
        },
        methods: {
            updateDataForum(id){
                this.$emit('updateDataForum', id);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>