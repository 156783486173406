var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"programEvent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{staticClass:"mb-3",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(_vm.isEdit)?_c('div',[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"rules":"required","name":"Сцена","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Название сцены")]),_c('v-select',{class:errors.length ? 'is-invalid' : '',attrs:{"placeholder":"Выберите сцену","options":_vm.forumData ? _vm.forumData.rooms : []},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных сцен ")]}},{key:"selected-option",fn:function(selected){return [_vm._v(" "+_vm._s(selected.label.split(',').join(', '))+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label.split(',').join(', '))+" ")]}}],null,true),model:{value:(_vm.program.room),callback:function ($$v) {_vm.$set(_vm.program, "room", $$v)},expression:"program.room"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"rules":"required|min:3|max:50","name":"Название лекции","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Название лекции")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Введите название лекции"},model:{value:(_vm.program.title),callback:function ($$v) {_vm.$set(_vm.program, "title", $$v)},expression:"program.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-md-6",attrs:{"rules":"required|min:3|max:250","name":"Описание лекции","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Описание лекции")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Краткое описание лекции"},model:{value:(_vm.program.description),callback:function ($$v) {_vm.$set(_vm.program, "description", $$v)},expression:"program.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"col-md-6 form-group"},[_c('label',[_vm._v("Дата и время лекции")]),_c('time-range',{model:{value:(_vm.programTime),callback:function ($$v) {_vm.programTime=$$v},expression:"programTime"}})],1)],1)]):_c('div',[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-xl-4",attrs:{"rules":"required","name":"Сцена","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Название сцены")]),_c('v-select',{class:errors.length ? 'is-invalid' : '',attrs:{"placeholder":"Выберите сцену","options":_vm.forumData ? _vm.forumData.rooms : []},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Извините, нет доступных сцен ")]}},{key:"selected-option",fn:function(selected){return [_vm._v(" "+_vm._s(selected.label.split(',').join(', '))+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label.split(',').join(', '))+" ")]}}],null,true),model:{value:(_vm.program.room),callback:function ($$v) {_vm.$set(_vm.program, "room", $$v)},expression:"program.room"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-xl-4 title-box",attrs:{"rules":"required|min:3|max:50","name":"Название лекции","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Название лекции")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Введите название лекции"},model:{value:(_vm.program.title),callback:function ($$v) {_vm.$set(_vm.program, "title", $$v)},expression:"program.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{staticClass:"col-xl-4 title-box",attrs:{"rules":"required|min:3|max:250","name":"Описание лекции","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Описание лекции")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Краткое описание лекции"},model:{value:(_vm.program.description),callback:function ($$v) {_vm.$set(_vm.program, "description", $$v)},expression:"program.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"col-xl-4 form-group time-box"},[_c('label',[_vm._v("Дата и время лекции")]),_c('time-range',{model:{value:(_vm.programTime),callback:function ($$v) {_vm.programTime=$$v},expression:"programTime"}})],1)],1)]),_c('event-speaker',{staticClass:"lecture-speaker-box",attrs:{"availableSpeakers":_vm.forumData ? _vm.forumData.speakers : [],"selectedSpeakers":_vm.selectedSpeakers},on:{"addSpeaker":_vm.addSpeaker,"deleteSpeaker":_vm.deleteSpeaker}}),(_vm.isEdit)?_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"type":"submit","variant":"primary submit btn-50"}},[_c('span',{staticClass:"btn-txt"},[_vm._v("сохранить")])])],1):_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary submit","disabled":!_vm.forumData._id || _vm.isBusy}},[(_vm.isBusy)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),(_vm.isBusy)?_c('span',{staticClass:"btn-txt"},[_vm._v("Сохранение...")]):_c('span',{staticClass:"btn-txt"},[_vm._v("добавить")])],1)],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }